@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.10);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}

.pyramidContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip-img:hover + .tooltip-text {
  opacity: 1;
}

.tooltip-text {
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  width: 120px;
  margin-bottom: 15px;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.video {
  position:relative;
  background-color:darkgray;
  display:flex;
  justify-content:center;
  align-items:center;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  height: 0;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shadow-button::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 10px;
  width: 100%;
  height: 100%;
  border: 2px solid white;
  border-radius: inherit;
  z-index: -1;
}

.play{
  height:50px;
  width:50px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}

.custom-container-bg {
  background-image: url("https://rocketsites.de/wp-content/uploads/2021/07/line-min.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media (max-width: 768px) {
  .custom-container-bg {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .video {
    height: 300px;
    width: 550px;
  }
}

.circle {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1e3b8a;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes ring-animation {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  25% {
    transform: scale(0.7);
    opacity: 0.7;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.3;
  }
  75% {
    transform: scale(1.1);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.circle::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 4px solid #1e3b8a;
  border-radius: 50%;
  opacity: 1;
  animation: ring-animation 3s linear infinite;
}

.markdown-container p {
  margin-bottom: 1em; /* oder ein anderer gewünschter Wert */
}

.markdown-container a {
  color: #1e3b8a; /* Oder die spezifische Schattierung von Blau, die du möchtest */
}

.markdown-container ul, .markdown-container ol {
  padding-left: 1em;
}

.markdown-container li {
  margin-bottom: 0.5em;
}

.markdown-container ul {
  list-style-type: disc;
}

.markdown-container ol {
  list-style-type: decimal;
}
